import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostFeed from "../components/postFeed"

export default function IndexPage({ data }) {
  return (
    <Layout>
      <SEO title="Blog" />
      <PostFeed />
    </Layout>
  )
}
